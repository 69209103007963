export const depositPaymentTypes = {
  jkos: { name: '街口支付', value: 'jkos', type: 'third-part' },
  newebpay: { name: '藍新支付', value: 'newebpay', type: 'third-part' },
  linepay: { name: 'LinePay', value: 'linepay', type: 'third-part' },
  wallet: { name: '儲值金', value: 'wallet', type: 'wallet' },
  'offline-jkos': { name: '街口支付（實體店）', value: 'offline-jkos', type: 'offline' },
  'offline-newebpay': { name: '信用卡（實體店）', value: 'offline-newebpay', type: 'offline' },
  'offline-linepay': { name: 'LinePay（實體店）', value: 'offline-linepay', type: 'offline' },
  'offline-cash': { name: '現金（實體店）', value: 'offline-cash', type: 'offline' },
  free: { name: '免付訂金', value: 'free', type: 'free' },
  offline: { name: '匯款（實體店）', value: 'offline', type: 'offline' },
}

export const staticDepositPaymentTypes = [
  { name: '現金（實體店）', value: 'offline-cash' },
  { name: '信用卡（實體店）', value: 'offline-newebpay' },
  { name: 'LinePay（實體店）', value: 'offline-linepay' },
  { name: '街口（實體店）', value: 'offline-jkos' },
]

export const depositStatusConfig = {
  unpaid: { name: '未付款', value: 'unpaid', tag: 'warning' },
  paid: { name: '已付款', value: 'paid', tag: 'action' },
  waitRefund: { name: '等待退款', value: 'waitRefund', tag: 'warning' },
  refund: { name: '已退款', value: 'refund', tag: 'info' },
  fail: { name: '付款失敗', value: 'fail', tag: 'danger' },
  expire: { name: '付款過期', value: 'expire', tag: 'danger' },
}

export const manualRefundPaymentType = {
  wallet: { name: '儲值金', value: 'wallet' },
  newebpay: { name: '藍新手動', value: 'newebpay_manual' },
  jkos: { name: '街口手動', value: 'jkos_manual' },
  linepay: { name: 'LINEPAY手動', value: 'linepay_manual' },
  pchomepay: { name: '支付連手動', value: 'pchomepay_manual' },
  offline: { name: '線下付款', value: 'offline' },
}

export const refundLinkConfig = {
  jkos: 'https://www.jkos.com/Merchant.Web/MerchantLogin/Login',
  newebpay: 'https://www.newebpay.com/main/login_center/single_login',
  linepay: 'https://pay.line.me/portal/tw/auth/login?isFooterConventionChanged=true',
}

export const paymentParamsMapping = {
  linepay: {
    linePayChannelId: 'linePayChannelId',
    linePayChannelSecretKey: 'linePayChannelSecretKey',
  },

  newebpay: {
    merchantId: 'newebpayMerchantId',
    hashKey: 'newebpayHashKey',
    hashIV: 'newebpayHashIV',

    newebpayMerchantId: 'merchantId',
    newebpayHashKey: 'hashKey',
    newebpayHashIV: 'hashIV',
  },

  jkos: {
    merchantId: 'jkosStoreId',
    apiKey: 'jkosApiKey',
    digest: 'jkosSercertKey',

    jkosStoreId: 'merchantId',
    jkosApiKey: 'apiKey',
    jkosSercertKey: 'digest',
  },

  atm: {
    atmBankCode: 'atmBankCode',
    atmBankName: 'atmBankName',
    atmBranchName: 'atmBranchName',
    atmAccountName: 'atmAccountName',
    atmAccountNumber: 'atmAccountNumber',
  },

  pchome: {
    pcHomePayAppID: 'pcHomePayAppID',
    pcHomePaySecret: 'pcHomePaySecret',
  },

}

export const configMapping = {
  enable: 'enable',
  // scas
  feeModel: 'paymentMode',
  feeModelServiceCountPrice: 'cash',
  feeModelTotalPriceRatio: 'percentege',
  //
  enableIgnoreMTag: '',
  enableSpecifyMTag: '',
  IgnoreMTags: 'memberExclude',
  SpecifyMTags: 'memberInclude',
  //
  enableSpecifyService: '',
  enableIgnoreService: '',
  SpecifyServices: '',
  IgnoreServices: '',
  //
  enableIgnoreServiceAttach: '',
  enableSpecifyServiceAttach: '',
  IgnoreServiceAttaches: '',
  SpecifyServiceAttaches: '',
  //
  enableSpecifyTimeRange: '',
  specifyTimeRangeEnd: '',
  specifyTimeRangeStart: '',
  //
  id: '',
  createdAt: '',
  updatedAt: '',
}
